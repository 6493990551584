.preloader{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: #DB58B8;
    z-index: 100;

    &.hide{
        opacity: 0;
    }

    .logo-cont{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
            path{
                opacity: 0;
            }

            // --key-duration: 1s;
            // @keyframes fadeInUpStaggered {
            //     0% {
            //         opacity: 0;
            //         transform: translateY(20px);
            //     }
            //     100% {
            //         opacity: 1;
            //         transform: translateY(0);
            //     }
            // }
            // @keyframes fadeOut {
            //     0% {
            //         opacity: 1;
            //         transform: translateY(0);
            //     }
            //     100% {
            //         opacity: 0;
            //         transform: translateY(-20px);
            //     }
            // }
              
            //   /* Assuming you have four path elements */
            // path:nth-of-type(1) {
            //     animation: fadeInUpStaggered var(--key-duration) 0.3s both infinite, fadeOut var(--key-duration) 2.5s both infinite;
            // }
              
            // path:nth-of-type(2) {
            //     animation: fadeInUpStaggered var(--key-duration) 0.6s both infinite, fadeOut var(--key-duration) 2.8s both infinite;
            // }
              
            // path:nth-of-type(3) {
            //     animation: fadeInUpStaggered var(--key-duration) 0.9s both infinite, fadeOut var(--key-duration) 3.1s both infinite;
            // }
              
            // path:nth-of-type(4) {
            //     animation: fadeInUpStaggered var(--key-duration) 1.2s both infinite, fadeOut var(--key-duration) 3.4s both infinite;
            // }






            

            @keyframes fadeFirst {
                0% {
                    opacity: 0;
                    transform: translateY(20px);
                }
                25% {
                    opacity: 1;
                    transform: translateY(0);
                }
                75% {
                    opacity: 1;
                    transform: translateY(0);
                }
                100% {
                    opacity: 0;
                    transform: translateY(-20px);
                }
            }

            --key-duration: 3s;
            @keyframes fadeInUpStaggered {
                0% {
                    opacity: 0;
                    transform: translateY(20px);
                }
                40% {
                    opacity: 0;
                    transform: translateY(20px);
                }
                // 25% {
                //     opacity: 1;
                //     transform: translateY(0);
                // }
                // 75% {
                //     opacity: 1;
                //     transform: translateY(0);
                // }
                50% {
                    opacity: 1;
                    transform: translateY(0);
                }
                90% {
                    opacity: 1;
                    transform: translateY(0);
                }
                100% {
                    opacity: 0;
                    transform: translateY(-20px);
                }
            }
              
              /* Assuming you have four path elements */
            path:nth-of-type(1) {
                animation: fadeFirst 2s 0s 1, fadeInUpStaggered var(--key-duration) 1.8s infinite;
            }
              
            path:nth-of-type(2) {
                animation: fadeFirst 2s 0.2s 1, fadeInUpStaggered var(--key-duration) 2.0s infinite;
            }
              
            path:nth-of-type(3) {
                animation: fadeFirst 2s 0.4s 1, fadeInUpStaggered var(--key-duration) 2.2s infinite;
            }
              
            path:nth-of-type(4) {
                animation: fadeFirst 2s 0.6s 1, fadeInUpStaggered var(--key-duration) 2.4s infinite;
            }

            
        }
    }
}