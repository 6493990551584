.preloader {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #DB58B8;
  z-index: 100;
}
.preloader.hide {
  opacity: 0;
}
.preloader .logo-cont {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.preloader .logo-cont svg {
  --key-duration: 3s;
  /* Assuming you have four path elements */
}
.preloader .logo-cont svg path {
  opacity: 0;
}
@keyframes fadeFirst {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  25% {
    opacity: 1;
    transform: translateY(0);
  }
  75% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}
@keyframes fadeInUpStaggered {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  40% {
    opacity: 0;
    transform: translateY(20px);
  }
  50% {
    opacity: 1;
    transform: translateY(0);
  }
  90% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}
.preloader .logo-cont svg path:nth-of-type(1) {
  animation: fadeFirst 2s 0s 1, fadeInUpStaggered var(--key-duration) 1.8s infinite;
}
.preloader .logo-cont svg path:nth-of-type(2) {
  animation: fadeFirst 2s 0.2s 1, fadeInUpStaggered var(--key-duration) 2s infinite;
}
.preloader .logo-cont svg path:nth-of-type(3) {
  animation: fadeFirst 2s 0.4s 1, fadeInUpStaggered var(--key-duration) 2.2s infinite;
}
.preloader .logo-cont svg path:nth-of-type(4) {
  animation: fadeFirst 2s 0.6s 1, fadeInUpStaggered var(--key-duration) 2.4s infinite;
}